<template>

  <p>404</p>

</template>


<script setup>

</script>


<style scoped>

</style>