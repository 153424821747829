import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router/router";
import CKEditor from '@ckeditor/ckeditor5-vue'
import './assets/css/style.css'
import './assets/vendor/bootstrap/css/bootstrap.min.css'
import './assets/vendor/bootstrap-icons/bootstrap-icons.css'
import './assets/vendor/bootstrap/js/bootstrap.bundle.min.js'
import './assets/vendor/php-email-form/validate.js'


const app = createApp(App)

app.use(router)
app.use(CKEditor)
router.beforeEach((to, from, next) => {
    // Define un título personalizado en la meta de la ruta
    const pageTitle = to.meta.title || 'Título predeterminado'
    document.title = pageTitle

    // Continúa con la navegación
    next()
})

app.mount('#app')
