import { createRouter,createWebHashHistory} from 'vue-router'

import NoPageFound from "@/modules/share/pages/NoPageFound.vue";

const routes = [
    { path: '/', component: () => import('@/modules/correo/pages/correoPage.vue'),
        name: 'Home',
        meta:{ title: 'Portal Mágico | ConvitEvent' },
    },
    { path: '/plantillas/:id', component: () => import('@/modules/seleccionarPlantilla/pages/plantillasPage.vue'),
        name: 'Plantillas',
        meta:{ title: 'Generar mi carta | ConvitEvent' },
    },
    { path: '/respuesta/:id/:id_carta' , component: () => import('@/modules/plantillaRespuesta/pages/plantillaRespuestaPage.vue'),
        name: 'Respuesta',
        meta:{ title: 'Portal Mágico | ConvitEvent' }

    },
    { path: '/descuento' , component: () => import('@/modules/seleccionarPlantilla/pages/descuento.vue'),
        name: 'descuento',
        meta:{ title: 'Regalo | ConvitEvent' }

    },
    { path: '/carta-respuesta/:id/:id_carta' , component: () => import('@/modules/plantillaRespuesta/pages/cartaRespuestaPage.vue'),
        name: 'Carta',
        meta:{ title: 'Carta de Respuesta | ConvitEvent' }
    },
    { path: '/:pathMatch(.*)*', component: NoPageFound },
]


// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
})


export default router